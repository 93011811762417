<template>
    <div>
        <custom-button class="mr-3" :colourType="buttonType" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </custom-button>

        <portal to="destination">
            <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
            :confirmationString='`Are you sure you want to \"${action.title}\"`'
            @removeFromDisplay="displayModal = false" @perform="perform()"
            >
                <template v-if="action.fields.get('client_id')">
                    <component :field="action.fields.get('client_id')"
                               :is="getFieldComponent(action.fields.get('client_id'))"
                                       fieldId="client_id" propertyKey="client"
                                       v-model="formData['client_id']"
                                       @input="setClientId"></component>
                    <div v-show="errors.has('client_id')" class="text-red-600">{{ errors.first('client_id') }}</div>

                    <dependant-field :action="action"
                                     fieldId="project_id"
                                     dependsOn="client_id"
                                     :dependsOnValue="clientId"
                                     v-model="formData['project_id']"
                                     @input="setProjectId"></dependant-field>
                    <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>
                </template>
                <template v-else>
                    <component :field="action.fields.get('project_id')"
                               :is="getFieldComponent(action.fields.get('project_id'))"
                                       fieldId="project_id" propertyKey="project"
                                       v-model="formData['project_id']"
                                       @input="setProjectId"></component>
                    <div v-show="errors.has('project_id')" class="text-red-600">{{ errors.first('project_id') }}</div>
                </template>

                <dependant-field @input="change" :action="action"
                                 fieldId="contract_id"
                                 dependsOn="project_id"
                                 :dependsOnValue="projectId"
                                 v-model="formData['contract_id']"
                ></dependant-field>
                <div v-show="errors.has('contract_id')" class="text-red-600">{{ errors.first('contract_id') }}</div>

                <!-- Block -->
                <component :is="getFieldComponent(action.fields.get('name'))" :field="action.fields.get('name')" v-model="formData['name']">
                </component>
                <div v-show="errors.has('name')" class="text-red-600">{{ errors.first('name') }}</div>

                <!-- Type -->
                <component :is="getFieldComponent(action.fields.get('type'))" :field="action.fields.get('type')" v-model="formData['type']" @change="setType" @input="setType">
                </component>
                <div v-show="errors.has('type')" class="text-red-600">{{ errors.first('type') }}</div>

                <!-- fields for residential type -->
                <template v-if="type !== null">
                    <div class="removeLabel">
                        <input id="chkUseImport" class="mr-2" type="checkbox" value="false" @input="setUsingImport"/>
                        <label class="dark:text-white" for="chkUseImport">Set {{setIdentifier1Title(type, 'using file')}}</label>

                        <property-or-field class="mt-2" v-if="!usingImport" :title="setIdentifier1Title(type, action.fields.get('identifiers').title)" :action="action"
                                           fieldId="identifiers" propertyKey="identifiers"
                                         v-model="formData['identifiers']"></property-or-field>
                        <div v-show="errors.has('identifiers') && !usingImport" class="text-red-600">{{ getIdentifiersErrors(type, errors.first('identifiers')) }}</div>

                        <p v-if="usingImport" class="text-sm leading-5 text-gray-500  my-2">Please upload the {{ setIdentifier1Title(type, '') }} in the correct format. <a @click="downloadTemplate(type)" class="underline cursor-pointer hover:text-gray-900">Click here to download the template.</a></p>

                        <property-or-field v-if="usingImport" class="mt-2" :title="setIdentifier1Title(type, action.fields.get('identifiers-via-csv').title)" :action="action"
                                           fieldId="identifiers-via-csv" propertyKey="identifiers-via-csv"
                                           v-model="formData['identifiers-via-csv']"></property-or-field>
                        <div v-show="errors.has('identifiers-via-csv') && usingImport" class="text-red-600">{{ errors.first('identifiers-via-csv') }}</div>
                    </div>
                </template>

                <!-- Templates -->
                <dependant-field @input="change" :action="action"
                                 fieldId="qa_category_ids"
                                 dependsOn="client_id"
                                 :dependsOnValue="clientId"
                                 v-model="formData['qa_category_ids']"
                ></dependant-field>
                <div v-show="errors.has('qa_category_ids')" class="text-red-600">{{ errors.first('qa_category_ids') }}</div>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

            </large-model>
        </portal>
    </div>
</template>

<script>
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    import DependantField from "@/components/DependantField";
    import fileDownload from "js-file-download";
    import LargeModel from "@/v3/models/LargeModel.vue";
    import PropertyOrField from "@/components/PropertyOrField";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                clientId: null,
                projectId: null,
                type: null,
                usingImport: false
            }
        },
        components: {
            LargeModel,
            CustomButton,
            PropertyOrField,
            DependantField
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            buttonType: {
              type: String,
              default: 'primary'
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                var that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined){
                        payload[field.name] = field.value
                        if(field.name === 'client_id'){
                            that.setClientId(field.value)
                        }
                    };
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                console.log(field);
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let next = res.body.links
                            .filter(link => link.rels.contains("self"))
                            .first();
                        let parts = next.href.split("/api");
                        this.$router.push(parts[1]);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            setProjectId(value) {
                this.projectId = value;
            },
            setClientId(value) {
                this.clientId = value;
            },
            setType(value) {
                this.type = value;
            },
            setIdentifier1Title(type, currentTitle){
                return currentTitle;
            },
            setIdentifier2Title(type){
                if (type === 'communal')
                {
                    return 'Area';
                }
                return 'Unit';
            },
            setUsingImport(element){
                this.usingImport = element.srcElement.checked;
            },
            getIdentifiersErrors(type, error){
                return error;
            },
            downloadTemplate(type){
                fileDownload('ID1\nID2\nID3\nID4','qa-tracker-csv-template.csv');
            }
        },

    }
</script>

<style lang="scss">
.removeLabel .form-group label{
    display:none
}
</style>
